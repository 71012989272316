// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-templates-contact-contact-tsx": () => import("./../../../src/components/templates/contact/contact.tsx" /* webpackChunkName: "component---src-components-templates-contact-contact-tsx" */),
  "component---src-components-templates-cookies-cookies-tsx": () => import("./../../../src/components/templates/cookies/cookies.tsx" /* webpackChunkName: "component---src-components-templates-cookies-cookies-tsx" */),
  "component---src-components-templates-courses-courses-tsx": () => import("./../../../src/components/templates/courses/courses.tsx" /* webpackChunkName: "component---src-components-templates-courses-courses-tsx" */),
  "component---src-layouts-storyblok-entry-storyblok-entry-tsx": () => import("./../../../src/layouts/storyblok-entry/storyblok-entry.tsx" /* webpackChunkName: "component---src-layouts-storyblok-entry-storyblok-entry-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-editor-tsx": () => import("./../../../src/pages/editor.tsx" /* webpackChunkName: "component---src-pages-editor-tsx" */)
}

